/* KEEP FOOTER BOTTOM ON SMALL CONTENT */

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.pusher {
    min-height: 100vh;
    margin-bottom: -470px;
}

.pusher:after {
    content: "";
    display: block;
    height: 470px;
}

.mobile-nav {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 999;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: white;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.no-scroll {
    overflow-y: hidden;
}

/*------------- #MAIN-HEADER --------------*/
.main-header {
    padding: 87px 86px 0px;
    position: relative;
    background-position: 50% 50%;
    min-height: 400px;
    width: 100%;
    margin-bottom: 30px;
}

@keyframes sideupscroll {
    0% {
        transform: translate3D(0%, 0%, 0); }
    50% {
        transform: translate3D(-50%, 0, 0); }
    100% {
        transform: translate3D(-100%, 0, 0); } }

.img-bottom {
    position: absolute;
    bottom: -14px;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 100%; }
img {vertical-align: middle; }


.bg-group-test:before {background-image: url('https://cdn.evbstatic.com/s3-build/perm_001/aa36c3/django/images/home/banners/homepage_hero_banner_2.jpg'); }
/*.bg-group-2:before {background-image: url('../images/dashboard/top-header2.png'); }*/
/*.bg-group-3:before {background-image: url('../images/dashboard/top-header3.png'); }*/
/*.bg-group-4:before {background-image: url('../images/dashboard/top-header4.png'); }*/
/*.bg-group-5:before {background-image: url('../images/dashboard/top-header5.png'); }*/
/*.bg-group-6:before {background-image: url('../images/dashboard/top-header6.png'); }*/
/*.bg-group-7:before {background-image: url('../images/dashboard/top-header7.png'); }*/
.bg-primary {background-color: #ff5e3a !important;}

.inline-items > * {
    display: inline-block;
    vertical-align: middle;
}

.main-header-content {
    color: #fff;
    text-align: center; }
.main-header-content > * {
    color: inherit; }
.main-header-content > *:first-child {
    font-weight: 300;
    margin-bottom: 20px; }
.main-header-content p {
    font-weight: 400;
    margin-bottom: 0; }

@media (max-width: 768px) {
    .main-header {
        max-width: 100%; } }

/*--------HEADER-LANDING--------*/
.content-bg-wrap {
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
    top: 1em;
    left: 0; }

.content-bg:before {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 200%;
    height: 100%;
    content: ""; }

.content-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    animation-name: sideupscroll;
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    will-change: transform;
    z-index: -1; }

.without-animation {
    animation: none;
}





/* Weather */
.main-header-wethear .date-and-place {
    position: absolute;
    top: 40px;
    left: 30px;
    color: #fff;
}
.main-header-wethear .wethear-update {
    position: absolute;
    top: 40px;
    right: 30px;
    font-size: 10px;
    color: #fff;
    fill: #fff;
}
.wethear-content {
    color: #fff;
    text-align: center;
}
.wethear-content .wethear-now {
    margin-bottom: 15px;
}
.wethear-content .temperature-sensor {
    font-size: 84px;
    font-weight: 300;
    margin-bottom: 10px;
    line-height: 1;
}
.wethear-content .wethear-now span {
    font-size: 13px;
}
.wethear-content .climate {
    font-size: 24px;
    margin-bottom: 15px;
}
.wethear-content .wethear-now-description {
    font-size: 12px;
}
.wethear-content .wethear-now-description > * {
    display: inline-block;
    padding: 0 25px;
}
.wethear-content .wethear-now-description span {
    font-weight: 700;
}
.wethear-content i {
    fill: #fff;
    margin-bottom: 20px;
}
.main-header-wethear .date-and-place {
    position: absolute;
    top: 40px;
    left: 30px;
    color: #fff;
}
.main-header-wethear .date {
    font-size: 16px;
}
.main-header-wethear .place {
    font-size: 11px;
}
.main-header-wethear .wethear-update {
    position: absolute;
    top: 40px;
    right: 30px;
    font-size: 10px;
    color: #fff;
    fill: #fff;
}


/* Events*/
.main-header-event .date-and-place {
    position: absolute;
    top: 40px;
    left: 30px;
    color: #fff;
}
.main-header-event .event-update {
    position: absolute;
    top: 40px;
    right: 30px;
    font-size: 10px;
    color: #fff;
    fill: #fff;
}
.event-content {
    color: #fff;
    text-align: center;
}
.event-content .event-now {
    margin-bottom: 15px;
}
.event-content .temperature-sensor {
    font-size: 75px;
    font-weight: 300;
    margin-bottom: 10px;
    line-height: 1.2em;
}
@media (max-width: 991px){

    .event-content .temperature-sensor {
        font-size: 50px;
    }
}

.event-content .event-now span {
    font-size: 13px;
}
.event-content .climate {
    font-size: 24px;
    margin-bottom: 15px;
}
.event-content .event-now-description {
    font-size: 12px;
}
.event-content .event-now-description > * {
    display: inline-block;
    padding: 0 25px;
}
.event-content .event-now-description span {
    font-weight: 700;
}
.event-content i {
    fill: #fff;
    margin-bottom: 20px;
}

.main-header-event .date-and-place {
    position: absolute;
    top: 40px;
    left: 30px;
    color: #fff;
}
.main-header-event .date {
    font-size: 16px;
}
.main-header-event .place {
    font-size: 11px;
}
.main-header-event .event-update {
    position: absolute;
    top: 40px;
    right: 30px;
    font-size: 10px;
    color: #fff;
    fill: #fff;
}




.event-item {
    padding: 25px;
}
.event-item.badges .icon {
    width: 38px;
    height: 38px;
    margin-right: 25px;
}
.event-item.badges .event-date,
.event-item .event-date
{
    font-size: 13px;
    color: #888da8;
}
.event-item .event-name{
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 700;
    line-height: 1.3;
    color: #515365;
    font-size: 1.25em;
}

.event-item.badges .skills-item,
.event-item .skills-item
{
    min-width: 220px;
    display: block;
    float: none;
    margin-bottom: 0;
    margin-top: 18px;
}
.skills-item .skills-item-meter {
    padding: 0;
    width: 100%;
    border-radius: 10px;
    background-color: #ebecf2;
    position: relative;
    height: 6px;
}
.event-item.badges .skills-item-meter-active {
    background-color: #ff9432;
}
.skills-item-meter-active.skills-animate {
    animation: skills-animated 1.2s ease-out;
}
.skills-item-meter-active {
    position: relative;
    height: 100%;
    display: inline-block;
    border-radius: 10px;
    opacity: 0;
    transition: all .2s ease;
    top: -8px;
}

.action-event {
    color: #515365;
    float: right;
}.action-event .right {
     float: right;
 }
.action-event a {
    color: #515365;
}

@media (max-width: 991px){
    .action-event {
        width: 100%;
        float: none ;
    }
}






@media (min-width: 1400px){

    .container {
        width: 1300px;
        max-width: 100%;
    }}
@media (min-width: 1199px){

    .container {
        width: 1110px;
        max-width: 100%;
    }}
@media (min-width: 1024px){

    .container {
        width: 900px;
        max-width: 100%;
    }}
@media (min-width: 800px){

    .container {
        width: 680px;
        max-width: 100%;
    }}
@media (min-width: 540px){

    .container {
        width: 600px;
        max-width: 100%;
    }}
@media (min-width: 1400px){

    .container {
        padding-right: 15px;
        padding-left: 15px;
    }}
@media (min-width: 1199px){

    .container {
        padding-right: 15px;
        padding-left: 15px;
    }}
@media (min-width: 1024px){

    .container {
        padding-right: 15px;
        padding-left: 15px;
    }}
@media (min-width: 800px){

    .container {
        padding-right: 15px;
        padding-left: 15px;
    }}
@media (min-width: 540px){

    .container {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 1400px){

    .container {
        width: 1300px;
        max-width: 100%;
    }}
@media (min-width: 1199px){

    .container {
        width: 1110px;
        max-width: 100%;
    }}
@media (min-width: 1024px){

    .container {
        width: 900px;
        max-width: 100%;
    }}
@media (min-width: 800px){

    .container {
        width: 680px;
        max-width: 100%;
    }}
@media (min-width: 540px){

    .container {
        width: 600px;
        max-width: 100%;
    }}
@media (min-width: 1400px){

    .container {
        padding-right: 15px;
        padding-left: 15px;
    }}
@media (min-width: 1199px){

    .container {
        padding-right: 15px;
        padding-left: 15px;
    }}
@media (min-width: 1024px){

    .container {
        padding-right: 15px;
        padding-left: 15px;
    }}
@media (min-width: 800px){

    .container {
        padding-right: 15px;
        padding-left: 15px;
    }}
@media (min-width: 540px){

    .container {
        padding-right: 15px;
        padding-left: 15px;
    }}

.container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
}

.empty-area{
    border: 2px dashed #bbc0d5;
    padding: 1em;
}

.statistics-column {
    padding-top: 0!important;
    padding-bottom: 0!important;
}
.statistics-list-count {
    margin-bottom: 0;
}
ul {
    list-style: none;
    padding: 0;
}
ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem;
}
ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem;
}



.statistics-list-count .indicator {
    font-size: 10px;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}
.negative {
    color: #ff5e3a;
}
.positive {
    color: #08ddc1;
}
.statistics-list-count .count-stat {
    font-size: 24px;
    color: #515365;
}
.statistics-list-count .points {
    margin-bottom: 0;
}
.points {
    font-size: 12px;
    margin-bottom: 30px;
}
.statistics-list-count li:last-child {
    margin-bottom: 0;
}
.statistics-list-count li {
    margin-bottom: 18px;
}
.ui-block {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #e6ecf5;
    margin-bottom: 15px;
}
.ui-block-content {
    padding: 24px 23px 23px;
}

/*ACTION*/
/*.people-bg{*/
/*    background-image:url('../images/dashboard/people-bg.jpg');*/
/*}*/
.w-action {
    background-color: #ff5e3a;;
    padding: 50px 45px 40px;
    text-align: center;
    color: #fff;
    border-radius: 5px;
}
.w-action .content .title {
    font-weight: 400;
    color: inherit;
    margin-bottom: 15px;
    font-size: 24px;
}
.w-action .content span {
    font-size: 10px;
    margin-bottom: 25px;
    display: block;
    text-transform: uppercase;
}
.w-action .btn {
    margin-bottom: 0;
}

/** Prevent problem on IOS **/
html.ios {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

html.ios,
html.ios body {
    height: initial !important;
}


[v-cloak] {
    display: none!important;
}
[v-cloak] > * { display:none }

/** Semantic Patch **/
.ui.modal .scrolling.content {
    max-height: calc(70vh);
    overflow: auto;
}

p {
    font-size: 1.15em; /*16px*/
}

h1, h2, h3, h5, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: 1.3em;
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: .5em 0;
}

h1, .h1 {
    font-size: 3.5em;
}

h2, .h2 {
    font-size: 2.7em;
}

h3, .h3 {
    font-size: 2.3em;
}

h4, .h4 {
    font-size: 2em;
}

h5, .h5 {
    font-size: 1.7em;
}

h6, .h6 {
    font-size: 1.15em;
}


.min-height-100p {
    min-height: 100% !important;
}

.max-width-5p {
    max-width: 5% !important;
}
.max-width-10p {
    max-width: 10% !important;
}

.min-height-100vh {
    min-height: 100vh !important;
    height: 100%;
}

.description, .info {
    color: #6e7179;
    letter-spacing: 1px;
    font-size: 15px;
}

.event.description {
    margin: auto;
    max-width: 500px;
}

.info-title {
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 600;
    color: #282C35;
    text-transform: uppercase !important;
}

/* Background */
.background-grey {
    background-color: #f7f8fa !important;
    background-clip: content-box;
}

.background-white {
    background-color: #fff !important;
    background-clip: content-box;
}

.fixed {
    position: fixed;
    z-index: 101;
    margin: 0em;
    width: 100%;
    top: 0;
}

.segment-square {

    position: relative;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
    padding: 1em;
    border: 1px solid rgba(34, 36, 38, .15);
}

.scrollable_horizontal {
    overflow-x: auto;
}

/* Position */
.vertical-align {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    vertical-align: middle !important;
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
}

.floated-right {
    float: right;
}

.floated-left {
    float: left;
}

/* Input */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.padding-all {
    padding: 1em 1em 1em 1em !important;
}

.padding-top {
    padding-top: 1em !important;
}

.padding-right {
    padding-right: 1em !important;
}

.padding-bottom {
    padding-bottom: 1em !important;
}

.padding-left {
    padding-left: 1em !important;
}

.padding-bottom-zero {
    padding-bottom: 0px !important
}

.padding-top-zero {
    padding-top: 0px !important
}

.padding-zero {
    padding: 0 0 0 0px !important
}

.margin-top {
    margin-top: 1em !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-top-30 {
    margin-top: 30px !important;
}

.margin-right {
    margin-right: 1em !important;
}

.margin-bottom {
    margin-bottom: 1em !important;
}

.margin-left {
    margin-left: 1em !important;
}

.margin-zero {
    margin: 0 0 0 0px !important
}

.margin-bottom-zero {
    margin-bottom: 0em !important;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.text-align-left {
    text-align: left;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-grab {
    cursor: grab;
}

.cursor-grabbing {
    cursor: grabbing;
}

.cursor-wait {
    cursor: wait;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.hidden {
    display: none !important;
}

/* X Line for text */
@media only screen and (max-width: 767px) {
    .two-line-mobile {
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .fluid-s {
        width: 100%;
    }

    .margin-top-s {
        margin-top: 1em !important;
    }

    .margin-right-s {
        margin-right: 1em !important;
    }

    .margin-bottom-s {
        margin-bottom: 1em !important;
    }

    .margin-left-s {
        margin-left: 1em !important;
    }

    .margin-zero-s {
        margin: 0 0 0 0px !important
    }

    .margin-bottom-zero-s {
        margin-bottom: 0em !important;
    }
    .ui.container.fluid-s {
        padding-left: 0em !important;
        padding-right: 0em !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .two-line-tablet {
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }
}

@media only screen and (min-width: 992px) {
    .for-line-computer {
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }
}

.oneLine {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.twoLine {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

/** WIDTH and DISPLAY**/

/*Computer*/
@media only screen and (min-width: 992px) {
    .ui.fraction.grid > [class*="sixteen-third wide computer"].column {
        width: 33.33333333% !important;
    }

    .ui.fraction.grid > [class*="sixteen-fith wide computer"].column {
        width: 20% !important;
    }

    .tablet-only {
        display: none !important;
    }

    .mobile-only {
        display: none !important;
    }

    .mobile-only.computer-only {
        display: block !important;
    }

    .tablet-only.tablet-only {
        display: block !important;
    }

    .mobile-only.tablet-only.computer-only {
        display: block !important;
    }

    .computer-hidden {
        display: none !important;
    }

    .computer-fixed {
        position: fixed;
        z-index: 101;
        margin: 0;
        width: 100%;
    }

    .computer-fixed {
        position: fixed;
        z-index: 101;
        margin: 0;
        width: 100%;
    }

    .computer-hidden {
        display: none !important;
    }
}

.fixed-bottom {
    bottom: 0;
    position: fixed;
    z-index: 101;
    margin: 0;
    width: 100%;
}

/*Tablet*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .ui.fraction.grid > [class*="sixteen-third wide tablet"].column {
        width: 33.33333333% !important;
    }

    .ui.fraction.grid > [class*="sixteen-fith wide tablet"].column {
        width: 20% !important;
    }

    .computer-only {
        display: none !important;
    }

    .mobile-only {
        display: none !important;
    }

    .mobile-only.tablet-only {
        display: block !important;
    }

    .tablet-only.computer-only {
        display: block !important;
    }

    .mobile-only.tablet-only.computer-only {
        display: block !important;
    }

    .tablet-fixed {
        position: fixed;
        z-index: 101;
        margin: 0;
        width: 100%;
    }

    .tablet-hidden {
        display: none !important;
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .ui.fraction.grid > [class*="sixteen-third wide mobile"].column {
        width: 33.33333333% !important;
    }

    .ui.fraction.grid > [class*="sixteen-fith wide mobile"].column {
        width: 20% !important;
    }

    .computer-only {
        display: none !important;
    }

    .tablet-only {
        display: none !important;
    }

    .mobile-only.tablet-only {
        display: block !important;
    }

    .mobile-only.computer-only {
        display: block !important;
    }

    .mobile-only.tablet-only.computer-only {
        display: block !important;
    }

    .mobile-fixed {
        position: fixed;
        z-index: 101;
        margin: 0;
        width: 100%;
    }

    .mobile-hidden {
        display: none !important;
    }

    .bottom-fixed-mobile {
        bottom: 0;
        position: fixed;
        z-index: 101;
        margin: 0;
        width: 100%;
    }
}

/* Container */
@media only screen and (max-width: 767px) {
    .ui.main.container {
        padding-left: 0em !important;
        padding-right: 0em !important;
    }

    .ui.main.large.container {
        /*min-width: 100% !important;*/
    }
}

@media only screen and (min-width: 768px) {
    .ui.main.container {
        padding-left: 1em !important;
        padding-right: 1em !important;
    }
}

.ui.main.container {
    max-width: 860px !important;
}

.ui.main.large.container {
    max-width: 100% !important;
}

.gaia-stretched > * {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.gaia-stretched {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

/** STRUCTURE **/
.gaia-dashboard-sidebar-left {
    position: relative;
}

.gaia-segment {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #e6ecf5;
    margin-bottom: 15px;
}

.gaia-title:first-child {
    border-top: none;
}

.gaia-title {
    padding: 23px 25px 18px;
    line-height: 1;
    display: table;
    width: 100%;
    position: relative;
}

.gaia-border-bottom {
    border-bottom: 1px solid #e6ecf5;
}

.gaia-border-top {
    border-top: 1px solid #e6ecf5;
}

.gaia-content {
    padding: 24px 23px 23px !important;
    padding: 24px 23px 23px !important;
}

.gaia-content label {
    color: #888da8;
}

.gaia-title:first-child {
    border-top: none;
}

.gaia-title {
    padding: 23px 25px 18px;
    line-height: 1;
    border-bottom: 1px solid #e6ecf5;
    border-top: 1px solid #e6ecf5;
    display: table;
    width: 100%;
    position: relative;
}

.gaia-title .title {
    color: #515365;
}

.ui.form.large-input input[type=text],
.ui.form.large-input input[type=email],
.ui.form.large-input input[type=search],
.ui.form.large-input input[type=password],
.ui.form.large-input input[type=date],
.ui.form.large-input input[type=datetime-local],
.ui.form.large-input input[type=tel],
.ui.form.large-input input[type=time],
.ui.form.large-input input[type=file],
.ui.form.large-input input[type=url],
.ui.form.large-input input[type=number],
.ui.form.large-input input,
.ui.form.large-input select,
.ui.form.large-input i.dropdown.icon,
.ui.form.large-input .dropdown {
    padding: 1em 1em !important;
}

.ui.form.large-input .search.selection.dropdown {
    padding: 1.1em 1em !important;
}

table.equal-column {
    table-layout: fixed;
    word-wrap: break-word;
}

/** TOP BAR **/
/*Style 1*/
nav.style1 .ui.menu {
    background-color: #fff;
    border-radius: 0;
}

nav.style1 .ui.menu .item.header-color {
    width: 70px;
    background-color: #fd5d39;
    border-radius: 0;
    margin: -1px -1px -1px -1px;
}

nav.style1 .ui.menu .item.header-color-font.blue {
    background-color: #2185d0;
    border-radius: 0;
    margin: -1px -1px -1px -1px;
}

nav.style1 .ui.menu .item.header-color-font {
    background-color: #fd5d39;
    border-radius: 0;
    margin: -1px -1px -1px -1px;
}

nav.style1 .ui.menu .item.header-color .ui.header,
nav.style1 .ui.menu .item.header-color-font .ui.header {
    margin: auto;
    color: #fff;
}

nav.style1 .ui.menu .item.header-color .ui.header > * {
    margin: auto;
    font-size: 2em;
}

nav.style1 .ui.menu .item i.fa {
    margin-right: 10px;
}

nav.style1 .ui.menu .item {
    color: #3f4257;
}

nav.style1 .ui.menu .item:hover {
    color: #3f4257;
}

nav.style1 .ui.menu .item div.ui.label.circular {
    margin: -26px 0 -12px -9px;
    z-index: 10;
    font-size: 10px;
}

/*Style 2*/
nav.style2 .ui.menu {
    background-color: #3f4257;
    border-radius: 0;
}

nav.style2 .ui.menu .item.header-color {
    width: 70px;
    background-color: #fd5d39;
    border-radius: 0;
    margin: -1px -1px -1px -1px;
}

nav.style2 .ui.menu .item.header-color .ui.header {
    margin: auto;
    color: #fff;
}

nav.style2 .ui.menu .item.header-color .ui.header > * {
    margin: auto;
    font-size: 2em;
}

nav.style2 .ui.menu .item i.fa {
    margin-right: 10px;
}

nav.style2 .ui.menu .item {
    color: #fff;
}

nav.style2 .ui.menu .item:hover {
    color: #fff;
}

nav.style2 .ui.menu .item div.ui.label.circular {
    margin: -26px 0 -12px -9px;
    z-index: 10;
    font-size: 10px;
}

/** SIDE BAR **/
/*Style 1*/
.nav-sidebar.style1, .nav-sidebar.style1 a {
    text-align: center;
    background-color: #26344b !important;
    color: #9A9FBF !important;
}

.nav-sidebar.style1 a div:not(.header) {
    cursor: pointer;
}

.nav-sidebar.style1 i {
    font-size: 24px;
}

.nav-sidebar.style1 a span {
    display: block;
    text-align: center;
    margin-top: 5px;
    color: #c3cee0;
}

.nav-sidebar.style1 div:hover:not(.header),
.nav-sidebar.style1 div.active {
    background-color: #212d41 !important;
}

/*Style 2*/
.nav-sidebar.style2 {
    width: 70px;
    min-height: 100%;
    padding-top: 3em;
    position: absolute;
    z-index: 1;
}

.nav-sidebar.style2, .nav-sidebar.style2 a {
    text-align: center;
    background-color: #fff !important;
    color: #9A9FBF !important
}

.nav-sidebar.style2 a div:not(.header) {
    cursor: pointer;
}

.nav-sidebar.style2 i {
    font-size: 24px;
    color: #9A9FBF !important
}

.nav-sidebar.style2 a span {
    display: block;
    text-align: center;
    margin-top: 5px;
    color: #9a9fbf;
    font-size: 10px;
}

.nav-sidebar.style2 div:hover:not(.header),
.nav-sidebar.style2 div.active {
    background-color: #fff !important;
}

/*@media only screen and (min-width: 992px) {*/
/*    .gaia-dashboard-sidebar-left article {*/
/*        margin-left: 70px;*/
/*    }*/
/*}*/

/* font */

/* ÉLÉMENT */
.gaia-toggle {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.26);
}

.gaia-toggle input[type=checkbox] {
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
}

.gaia-toggle input[type=checkbox] + .toggle {
    content: "";
    display: inline-block;
    width: 66px;
    height: 30px;
    background-color: rgba(80, 80, 80, 0.7);
    border-radius: 15px;
    margin-right: 0;
    transition: background 0.3s ease;
    vertical-align: middle;
    text-align: left;
    margin-left: 5px;
    cursor: pointer;
}

.gaia-toggle input[type=checkbox]:checked + .toggle {
    background-color: #2185d0;
}

.gaia-toggle input[type=checkbox]:checked + .toggle:after {
    left: 37px;
}

.gaia-toggle input[type=checkbox] + .toggle:after {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 26px;
    background-color: #FFFFFF;
    border-radius: 20px;
    position: relative;
    left: 3px;
    top: 3px;
    transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
    text-align: center;
}

/* Button */
.ui.checked.button:after {
    position: absolute;
    font-family: FontAwesome;
    content: "\f00c";
    top: 50%;
    left: 50%;
    margin: -.64285714em 0 0 -.64285714em;
    width: 1.28571429em;
    height: 1.28571429em;
    color: #fff;

}

.ui.checked.button {
    position: relative;
    cursor: default;
    text-shadow: none !important;
    color: transparent !important;
    opacity: 1;
    pointer-events: auto;
    -webkit-transition: all 0s linear, opacity .1s ease;
    transition: all 0s linear, opacity .1s ease;
}

.ui.fail.button:after {
    position: absolute;
    font-family: FontAwesome;
    content: "\f00d";
    top: 50%;
    left: 50%;
    margin: -.64285714em 0 0 -.64285714em;
    width: 1.28571429em;
    height: 1.28571429em;
    color: #fff;

}

.ui.fail.button {
    position: relative;
    cursor: default;
    text-shadow: none !important;
    color: transparent !important;
    opacity: 1;
    pointer-events: auto;
    -webkit-transition: all 0s linear, opacity .1s ease;
    transition: all 0s linear, opacity .1s ease;
}

/* Events */
.gaia-card {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: box-shadow .2s cubic-bezier(0, .2, .4, 1);
    background-color: #fff;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, .1);
    display: block;
}

.gaia-card .gaia-card-main {
    display: block;
}

.gaia-card .gaia-card-header,
.gaia-card .gaia-event-card-header {
    position: relative;
    line-height: 0;
}

.gaia-card .gaia-card-header .gaia-card-image,
.gaia-card .gaia-event-card-header .gaia-card-image {
    -webkit-transition: background-color .5s;
    -moz-transition: background-color .5s;
    transition: background-color .5s;
    overflow: hidden;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/background-basic.png');
    background-size: cover;
    background-position : center center;
}

.gaia-card .gaia-card-header .gaia-card-image img,
.gaia-card .gaia-event-card-header .gaia-card-image img {
    max-width: 100%;
    max-height: 100%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    position: relative;
    top: 50%;
}

.gaia-card .gaia-card-header .gaia-card-label,
.gaia-card .gaia-event-card-header .gaia-card-label {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .92);
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    color: #282C35;
    box-sizing: border-box;
    padding: 2px 10px;
    position: absolute;
    display: inline-block;
    top: 10px;
    right: 10px;
}

.gaia-card .gaia-card-header:after,
.gaia-card .gaia-event-card-header:after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    display: block;
    content: "\00A0";
    width: 100%;
    background: #EFF2F5;
}

.gaia-card .gaia-card-body {
    padding: 5px 15px;
}

.gaia-card .gaia-card-body .gaia-card-date {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    color: #45494E;
    font-size: 12px;
    line-height: 18px;
    height: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 5px 0 0;
}

.gaia-card .gaia-card-body .gaia-card-title {
    display: block;
    display: -webkit-box;
    max-height: 38px;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #282C35;
    font-size: 15px;
    line-height: 19px;
    max-height: 38px;
    font-weight: 600;
    letter-spacing: 0;
    padding: 0;
    height: 38px;
}

.gaia-card .gaia-card-body .gaia-card-venue {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #666A73;
    font-size: 12px;
    line-height: 18px;
    height: 18px;
    padding: 5px 0 0;
}

.gaia-card .gaia-card-footer {
    position: relative;
    height: 40px;
    line-height: 40px;
    padding-right: 80px;
    border-top: 1px solid #EFF2F5;
    padding-left: 15px;
}

.gaia-card .gaia-card-footer .gaia-card-tags {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.gaia-card .gaia-card-footer .gaia-card-actions {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    line-height: 38px;
}

.gaia-card .gaia-card-footer .gaia-card-actions a, .gaia-card .gaia-card-footer .gaia-card-actions span {
    display: inline-block;
    color: #666A73;
    font-size: 20px;
    width: 40px;
    height: 40px;
    border-left: 1px solid #EFF2F5;
    box-sizing: border-box;
    text-align: center;
}

.gaia-card .gaia-card-footer .gaia-card-actions i {
    position: relative;
    left: 1px;
}

.gaia-card .gaia-card-footer i {
    line-height: 38px;
}

.gaia-card .gaia-card-footer .gaia-card-actions span {
    display: inline-block;
    color: #666A73;
    font-size: 20px;
    width: 40px;
    height: 40px;
    border-left: 1px solid #EFF2F5;
    box-sizing: border-box;
    text-align: center;
}

.gaia-card .gaia-event-card-header:before {
    display: block;
    content: "\00A0";
    width: 100%;
    padding-top: 50%;
}

.gaia-card .gaia-card-header:before {
    display: block;
    content: "\00A0";
    width: 100%;
    padding-top: 100%;
}

.masthead h1.ui.header {
    font-size: 4em;
    font-weight: normal;
}

.masthead {
    padding-top: 4em;
    padding-bottom: 4em;
    background-color: #5b5f68 !important;
}

/** Segment **/
.ui.segment.surrounded.blue {
    border: 2px solid #2185d0;
}

/*upgrade semantic*/
.ui.fluid.card.green.segment.clicked {
    border: 3px solid #21ba45 !important;
    box-shadow: none!important;
}

[v-cloak] {
    display: none;
}

.link{
    cursor: pointer;
    color: #4183c4!important;
}

/** Segment **/
.ui.segments.spaced{
    box-shadow: none!important;
    border: none!important;
}
.segment.spaced{
    margin-top: 20px!important;
    border-top:none!important;
    border-radius: 10px!important;
    box-shadow: 1px 1px 1px 0 rgba(34, 36, 38, 0.1)!important;
}

.segment.spaced>.top.attached.ui.label{
    border-top-left-radius: 10px!important;
    border-top-right-radius: 10px!important;
}


/** Footer **/
.footer {
    background-color: #121921;
    padding: 120px 0 0;
    position: relative;
}

.info .logo-title {
    margin-right: 20px;
    font-weight: 800;
    color: #0083ff;
    font-size: 45px;
    display: inline-block;
    font-family: 'Baloo Paaji 2', cursive !important;
    line-height: .7;
}

.info .logo-sub-title {
    color: #a1b7d2;
    font-size: 14px;
    font-weight: 700;
    display: inline-block;
}

.info .learn-more {
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    transition: all 0.15s linear;
}

.info .learn-more:hover {
    color: #0083ff;
}

.footer .list, .footer .list a {
    overflow: hidden;
    margin-left: 0;
    color: #4b5d73;
    margin-right: 8px;
    font-size: 1.1em;
    font-weight: 400;
}

.sub-footer {
    background-color: #fff;
    padding: 35px 0;
    text-align: center;
    position: relative;
    margin-top: 120px;
}

.sub-footer span {
    font-size: 14px;
    margin-bottom: 1px;
    color: #6987ab;
    display: block;
}

.sub-footer .title {
    color: #0083ff;
    font-weight: 700;
}

.mt30 {
    margin-top: 30px;
}

.grid.relaxed {
    max-width: 100%;
    margin: 0 !important;
}

.grid.relaxed > .column {
    padding: 0 !important;
}

.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 500;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    padding: 40px;
    transform: translateY(-105%);
    transition: transform 0.5s cubic-bezier(0.22, 1, 0.36, 1);
}

.mobile-menu.opened {
    transform: translateY(0);
}

.mobile-menu i {
    position: absolute;
    top: 15px;
    right: 15px;
}

.mobile-menu a {
    margin-top: 20px;
    color: black;
    text-decoration: none;
}

.menu-dimmer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 444;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.events-banner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.events-banner img {
    height: 142px;
    width: 142px;
    margin-right: 32px;
}

.events-banner .text {
    text-align: left;
}

@media (max-width: 900px) {
    .events-banner {
       flex-direction: column;
    }

    .events-banner img {
        margin: 0 0 25px 0;
    }
    .events-banner .text {
        text-align: center;
    }

    .events-banner h2 {
        display: none;
    }
}

/** Loader **/
i.icon.loading.speed {
    animation: loader 1s linear infinite;
}
